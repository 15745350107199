import React from "react";
import { Typography } from "@mui/material";

const ImageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

const Footer = () => {
  return (
    <div id="footer" className="footer">
      <Typography id="footerText" className="footerText">
        Didukung oleh:
      </Typography>
      <img
        id="kai-footer-logo"
        src={ImageBaseUrl + "/KAIFooterLogo.png"}
        alt="KAI"
        height={"17"}
        width={"42"}
      />
    </div>
  );
};

export default Footer;
