import React, { useState } from "react";
import { Header, Loader } from "..";
import "./ChangePassengerSeat.css";
import PassengerDetailCards from "./PassengerDetailCards";
import SubmitSeat from "./SubmitSeat";
import SelectSeatContainer from "./SelectSeatContainer";

const ChangePassengerSeat = () => {
  const [seatChange, setSeatChange] = useState(false);
  const [loading, setLoading] = useState(false);

  if (loading) {
    return (
      <>
        <Loader loading={loading} />
        <Header
          header={"Pilih Kursi"}
          back={"/verefiedinputdata"}
          step={"5"}
          close={false}
        />
        <SubmitSeat />
      </>
    );
  }

  return (
    <div>
      <Header
        header={"Pilih Kursi"}
        back={"/verefiedinputdata"}
        step={"5"}
        close={false}
      />
      <PassengerDetailCards seatChange={seatChange} setLoading={setLoading} />
      <SelectSeatContainer setSeatChange={setSeatChange} />
      <SubmitSeat />
    </div>
  );
};

export default ChangePassengerSeat;
