import React, { useEffect, useState } from "react";
import "../Styles/InputData.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TrainIcon from "@mui/icons-material/Train";
import {
  Header,
  Footer,
  ContactDetails,
  PassengerInfo,
  Loader,
} from "../index";
import { bookTicketApi } from "../../api/ApiUtils";
import { getWagonClass, priceFormatter } from "../../common/Utils";
import { PopUpModal } from "../CommonComponents/PopUp/PopUpModal";

const InputData = () => {
  const header = "Isi Data";
  const navigate = useNavigate();
  const [stationTiciketCard, setStationTiciketCard] = useState({});
  const [errorPopUp, showErrorPopUp] = useState({ showPopUp: false });
  const [loading, setLoading] = useState(false);
  const [paxName, setPaxName] = useState(false);
  useEffect(() => {
    let KAI = JSON.parse(localStorage.getItem("KAI"));
    if (!KAI?.stationDetails || !KAI?.ticketDetails) {
      navigate("/");
    } else {
      const { stationDetails, ticketDetails } = KAI;
      let stationTiciketCard = {
        departdate: ticketDetails.departdate,

        originStation: stationDetails.sourceStation.stationname,
        destinationStation: stationDetails.destnationStation.stationname,

        departureTime: ticketDetails.departuretime,
        arrivalTime: ticketDetails.arrivaltime,

        trainName: ticketDetails.trainname,
        wagonClass: ticketDetails.wagonclasscode,
        subClass: ticketDetails.subclass,

        adultPassengers: stationDetails.adultPassengers,
        childPassengers: stationDetails.childPassengers,
        totalPassengers:
          parseInt(stationDetails.adultPassengers) +
          parseInt(stationDetails.childPassengers),
        totalAmount:
          parseInt(stationDetails.adultPassengers) *
          parseInt(ticketDetails.fares[0].amount),
      };
      setStationTiciketCard(stationTiciketCard);
    }
  }, []);

  const [error, setError] = useState(false);
  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }, [error]);

  const handlePesanSubmit = () => {
    let p = new Promise((resolve, reject) =>
      resolve(JSON.parse(localStorage.getItem("KAI")))
    );
    p.then(async (response) => {
      if (response?.passengerList && response?.customerContactDetails) {
        let {
          passengerList,
          customerContactDetails,
          ticketDetails,
          stationDetails,
        } = response;
        // check for customerContactDetails contains all the data.
        let { name, phoneCode, phoneNo, email } = customerContactDetails;
        let customerContactDetailsCheck =
          name != "" && phoneCode != "" && phoneNo != "" && email != "";

        // min no.of passenger is 1 so there will be always atleast 1 passenger.
        // check for passengerList contains all the data.
        let passengerListCheck = true,
          i;

        for (i = 0; i < passengerList.length; i++) {
          passengerListCheck =
            passengerList[i].name != "" &&
            ((passengerList[i].passengerID == "Paspor" &&
              passengerList[i].numberID.length == 8) ||
              (passengerList[i].passengerID == "KTP" &&
                passengerList[i].numberID.length == 16)) &&
            (passengerList[i].passenger == "adult" ||
              (passengerList[i].passenger == "child" &&
                moment(stationTiciketCard.departdate, "YYYY-MM-DD").diff(
                  moment(passengerList[i].childBirthDate).subtract(1, "days"),
                  "years"
                ) < 3));
          if (passengerListCheck == false) break;
        }

        if (passengerListCheck && customerContactDetailsCheck) {
          setError(false);
          let paxes = passengerList.map((item) => {
            let passenger = {
              idNumber: item.numberID,
              name: item.name,
              passengerType: item.passenger === "adult" ? "A" : "I",
              idNumType: item.passengerID === "KTP" ? 1 : 4,
            };
            if (item.passenger == "child") {
              passenger.dob = moment(item.childBirthDate).format("YYYY-MM-DD");
            } else {
              passenger.dob = "";
            }
            return passenger;
          });

          let request = {
            propScheduleId: ticketDetails.propscheduleid,
            tripId: ticketDetails.tripid,
            originId: ticketDetails.originid,
            destinationId: ticketDetails.destinationid,
            stasiunOrginCode: ticketDetails.stasiunorgcode,
            stasiunDestCode: ticketDetails.stasiundestcode,
            tripDate: ticketDetails.tripdate,
            departDate: ticketDetails.departdate,
            noka: ticketDetails.noka,
            wagonClassCode: ticketDetails.wagonclasscode,
            wagonClassId: ticketDetails.wagonclassid,
            customerName: name,
            phone: phoneNo,
            email: email,
            subClass: ticketDetails.subclass,
            ticketType: "R",
            totPsgAdult: stationDetails.adultPassengers,
            totPsgChild: 0,
            totPsgInfant: stationDetails.childPassengers,
            paxes: paxes,
          };
          setLoading(true);
          let response = await bookTicketApi(request);
          if (response.success) {
            const KAI = JSON.parse(localStorage.getItem("KAI"));
            KAI.bookingcode = response.data.bookingcode;
            localStorage.setItem("KAI", JSON.stringify(KAI));
            setLoading(false);
            navigate("/verefiedinputdata");
          } else {
            setLoading(false);
            showErrorPopUp({
              ...errorPopUp,
              showPopUp: true,
              message: response.message,
            });
          }
        } else {
          setError(true);
        }
      } else {
        setError(true);
      }
    });
  };
  const handlePopUpClose = () => {
    showErrorPopUp({ ...errorPopUp, showPopUp: false });
  };

  return (
    <div id={"InputData"}>
      <Loader loading={loading} />
      <PopUpModal
        open={errorPopUp.showPopUp}
        error={true}
        message={errorPopUp.message}
        handlePopUpClose={handlePopUpClose}
      />
      <Header header={header} back={"/stationpricecard"} step={"3"} />
      <div id={"InputDataForm"} style={{ padding: "16px" }}>
        <StationTicketCard stationTiciketCard={stationTiciketCard} />

        <ContactDetails submitError={error} setPaxName={setPaxName} />

        <PassengerInfo
          ticiketInfo={stationTiciketCard}
          submitError={error}
          paxName={paxName}
        />

        <PricingDetails stationTiciketCard={stationTiciketCard} />

        <Button
          id={"Pesan"}
          fullWidth
          children={"Pesan"}
          variant={"contained"}
          className={"submitOrangeButton"}
          onClick={() => handlePesanSubmit()}
        />
      </div>
      <Footer />
    </div>
  );
};
export default InputData;

const StationTicketCard = ({ stationTiciketCard }) => (
  <Paper
    id={"selectedStationTicketCard"}
    className={"paperCards"}
    style={{ padding: "0" }}
  >
    <div
      id={"selectedStationTicketCard-date"}
      style={{
        padding: "16px 16px 8px 16px",
        background: "#F2F2F2",
        borderRadius: "8px 8px 0 0",
        display: "flex",
        alignItems: "center",
      }}
    >
      <TrainIcon
        style={{ color: "#999", fontSize: "18px", marginRight: "6px" }}
      />
      <Typography
        id={"selectedStationTicketCard-date-value"}
        style={{ fontWeight: "500", fontSize: "12px", color: "#555" }}
        children={moment(stationTiciketCard.departdate, "YYYY-MM-DD").format(
          "ddd, DD MMM YYYY"
        )}
      />
    </div>
    <div style={{ margin: "8px 0", padding: "0 16px" }}>
      <div
        id={"selectedStationTicketCard-name"}
        style={{ display: "flex", alignItems: "center" }}
      >
        <Typography
          id={"selectedStationTicketCard-name-start"}
          children={stationTiciketCard.originStation}
          className={"stationNames"}
        />
        <ArrowForwardIcon
          fontSize="18"
          style={{ margin: "0 5px" }}
          className={"iconArrowInSelectInput"}
        />
        <Typography
          id={"selectedStationTicketCard-name-end"}
          children={stationTiciketCard.destinationStation}
          className={"stationNames"}
        />
      </div>
      <div
        id={"selectedStationTicketCard-time"}
        style={{ display: "flex", alignItems: "center", marginTop: "4px" }}
      >
        <Typography
          id={"selectedStationTicketCard-time-start"}
          children={moment(stationTiciketCard.departureTime, "HHm").format(
            "HH:mm"
          )}
          className={"ticketDetailcss"}
        />
        <Divider style={{ width: "1vw", margin: "0 5px" }} />
        <Typography
          id={"selectedStationTicketCard-time-end"}
          children={moment(stationTiciketCard.arrivalTime, "HHm").format(
            "HH:mm"
          )}
          className={"ticketDetailcss"}
        />
      </div>
    </div>
    <div
      id={"selectedStationTicketCard-class"}
      style={{
        display: "flex",
        alignItems: "center",
        padding: "0 16px 16px 16px",
      }}
    >
      <Typography
        id={"selectedStationTicketCard-train-name"}
        children={stationTiciketCard.trainName}
        className={"ticketDetailcss"}
      />
      <Divider style={{ width: "1vw", margin: "0 5px" }} />
      <Typography
        id={"selectedStationTicketCard-class-name"}
        children={getWagonClass(
          stationTiciketCard.wagonClass,
          stationTiciketCard.subClass
        )}
        className={"ticketDetailcss"}
      />
    </div>
  </Paper>
);

const PricingDetails = ({ stationTiciketCard }) => {
  let totalAmount = priceFormatter(stationTiciketCard.totalAmount || 0);
  return (
    <div style={{ margin: "24px 0 0 0" }}>
      <Typography children={"Rincian Harga"} className={"inputSectionLabels"} />
      <div style={{ marginTop: "16px" }}>
        <Accordion id={"RincianHarga-dropdown"} className={"dropShadow"}>
          <AccordionSummary style={{ borderBottom: "1px solid #DDD" }}>
            <div style={{ width: "100%" }} className={"displayFlex"}>
              <div className={"displayFlex"}>
                <KeyboardArrowDownIcon
                  style={{ marginRight: "12px" }}
                  className={"iconArrowInSelectInput"}
                />
                <Typography
                  id={"Harga-Yang-Anda-Bayar"}
                  children={"Harga yang Anda bayar"}
                  style={{ fontWeight: "500", fontSize: "12px", color: "#666" }}
                />
              </div>
              <Typography
                id={"Harga-Yang-Anda-Bayar-value"}
                children={`Rp ${totalAmount}`}
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  color: "#1A1A1A",
                }}
              />
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ padding: "16px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={"displayFlex"}>
                <Typography
                  id="Argo-Dewasa"
                  children={`Argo (Dewasa) x ${stationTiciketCard.adultPassengers}`}
                  style={{ fontWeight: "500", fontSize: "12px", color: "#666" }}
                />
                <Typography
                  id="Argo-Rp"
                  children={`Rp ${totalAmount}`}
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#1A1A1A",
                  }}
                />
              </Grid>
              <Grid item xs={12} className={"displayFlex"}>
                <Typography
                  id="Argo-Bayi"
                  children={`Argo (Bayi) x ${stationTiciketCard.childPassengers}`}
                  style={{ fontWeight: "500", fontSize: "12px", color: "#666" }}
                />
                <Typography
                  id="GRATIS"
                  children={"GRATIS"}
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#179D4F",
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};
