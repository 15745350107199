export const popularStationList = [
  {
    id: 161,
    stationcode: "BD",
    stationname: "BANDUNG",
  },
  {
    id: 308,
    stationcode: "CN",
    stationname: "CIREBON",
  },
  {
    id: 547,
    stationcode: "GMR",
    stationname: "GAMBIR",
  },
  {
    id: 649,
    stationcode: "PSE",
    stationname: "PASAR SENEN",
  },
  {
    id: 264,
    stationcode: "ML",
    stationname: "MALANG",
  },
  {
    id: 390,
    stationcode: "SMC",
    stationname: "SEMARANG PONCOL",
  },
  {
    id: 391,
    stationcode: "SMT",
    stationname: "SEMARANG TAWANG",
  },
  {
    id: 404,
    stationcode: "SBI",
    stationname: "SURABAYA PASARTURI",
  },
  {
    id: 251,
    stationcode: "YK",
    stationname: "YOGYAKARTA",
  },
];
