import React from "react";
import "../../Styles/Spinner.css";
import { Typography } from "@mui/material";
import { Footer, Header } from "../..";

const RedirectPage = () => {
  const header = "Ringkasan";
  return (
    <div id="redirectPage" className="stationlistContainer">
      <Header header={header} back={""} close={false} />
      <div className="redirectPageText">
        <div className="loadingio-spinner-spin-yq8n5svaih">
          <div className="ldio-dhj58xzoplv">
            <div>
              <div></div>
            </div>
            <div>
              <div></div>
            </div>
            <div>
              <div></div>
            </div>
            <div>
              <div></div>
            </div>
            <div>
              <div></div>
            </div>
            <div>
              <div></div>
            </div>
            <div>
              <div></div>
            </div>
            <div>
              <div></div>
            </div>
            <div>
              <div></div>
            </div>
            <div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <Typography style={{ fontWeight: "bold" }} className="redirectPageText">
        Mohon Tunggu
      </Typography>
      <Typography id="redirectPageText" className="redirectPageText">
        Anda sedang diarahkan ke halaman lain.
      </Typography>
      <Typography className="redirectPageText">
        Proses ini memakan waktu 10 detik
      </Typography>
      <Footer />
    </div>
  );
};

export default RedirectPage;
