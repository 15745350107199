import React, { useContext } from "react";
import { PassengerContext } from "../Container";
import "./Passenger.css";
import { Typography } from "@mui/material";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const PassengerContainer = () => {
  const Context = useContext(PassengerContext);
  const { setOpenPassengerDialog, openPassengerDialog, passengerInfo } =
    Context;

  return (
    <div style={{ marginBottom: "24px" }}>
      <div className={"displayInlineFlex"} style={{ width: "100%" }}>
        <GroupsOutlinedIcon className={"iconColor"} />
        {/* popup div */}
        <div
          className={"displayFlex"}
          style={{ width: "100%" }}
          onClick={() => setOpenPassengerDialog(!openPassengerDialog)}
        >
          <div style={{ marginLeft: "18px" }}>
            <Typography className={"formTitle"} children={"Penumpang"} />
            <Typography id={'selectedPassenger'} className={"formData"}>
              {passengerInfo.adult + " Dewasa"}
              {passengerInfo.child ? ", " + passengerInfo.child + " Bayi" : ""}
            </Typography>
          </div>
          <KeyboardArrowDownIcon className={"iconColor"} />
        </div>
      </div>
    </div>
  );
};

export default PassengerContainer;
