import React from "react";
import "./Homepage.css";
import Container from "./Container/Container";

const HomePage = () => {
  return (
    <div id="HomePage">
      <Container />
    </div>
  );
};

export default HomePage;
