import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { getWagonClass } from "../../common/Utils";
import { handleChangeSeat } from "../VerifiedInputData/SubmitedPassengerInfo";
import { useNavigate } from "react-router-dom";
import { PopUpModal } from "../CommonComponents/PopUp/PopUpModal";

const PassengerDetailCards = ({ seatChange, setLoading }) => {
  const navigate = useNavigate();
  const [passengers, setPassengers] = useState([]);
  const [passengersList, setPassengersList] = useState([]);
  const KAI = JSON.parse(localStorage.getItem("KAI"));
  const [errorPopUp, showErrorPopUp] = useState({ showPopUp: false });

  let verifiedPassenger = {
    paxes: KAI.ticketBookingResponse.data.paxes,
    destinationid: KAI.ticketDetails.destinationid,
    originId: KAI.ticketDetails.originid,
    subClass: KAI.ticketBookingResponse.data.subclass,
    wagonClassId: KAI.ticketBookingResponse.data.wagonclassid,
    tripId: KAI.ticketBookingResponse.data.tripid,
    paymentcode: KAI.ticketBookingResponse.data.paymentcode,
  };

  useEffect(() => {
    const currentIndex = getIndex(passengers);
    document.getElementById(`passengerCard-${currentIndex}`)?.scrollIntoView();
  }, [passengers, passengersList]);

  useEffect(() => {
    setPassengers(KAI?.seatList?.currentSeat?.idnumber);
    setPassengersList(KAI?.seatList?.paxes);
  }, [KAI?.seatList?.currentSeat?.idnumber]);

  const getIndex = (idnumber) => {
    for (let index = 0; index < passengersList.length; index++) {
      if (passengersList[index].idnumber === idnumber) {
        return index;
      }
    }
  };

  const handleChangePax = (index) => {
    let doNavigate = false;
    handleChangeSeat(
      passengersList[index],
      navigate,
      setLoading,
      verifiedPassenger,
      showErrorPopUp,
      errorPopUp,
      doNavigate
    );
  };

  const handlePopUpClose = () => {
    showErrorPopUp({ ...errorPopUp, showPopUp: false });
  };

  return (
    <>
      <PopUpModal
        open={errorPopUp.showPopUp}
        error={true}
        message={errorPopUp.message}
        handlePopUpClose={handlePopUpClose}
      />
      <div
        style={{
          background: "#F7F6F6",
          display: "flex",
          alignItems: "center",
          overflowX: "scroll",
        }}
        className="paxDetailCardList"
      >
        {passengersList.map(
          (
            {
              idnumber,
              name,
              wagonclasscode,
              stamformdetcode,
              wagondetrow,
              wagondetcol,
              passengertype,
            },
            i
          ) => {
            const index = getIndex(idnumber) + 1;
            return (
              passengertype === "A" && (
                <div
                  style={{ padding: "16px 4px" }}
                  key={i}
                  id={`passengerCard-${i}`}
                  onClick={() => {
                    handleChangePax(i);
                  }}
                >
                  <div
                    className={`${"displayFlex-1"} ${"cardStyle"}`}
                    id="passenger-card"
                    style={{
                      border:
                        idnumber === passengers
                          ? "1px solid #2196f3"
                          : "1px solid #DDD",
                    }}
                  >
                    <div>
                      <Typography className={"passengerIndex"}>
                        {index}
                      </Typography>
                    </div>
                    <div style={{ marginLeft: "12px" }}>
                      <div className={"displayFlex"}>
                        <Typography
                          id={`passengerName-${i}`}
                          className={"passengerCardPrimaryInfo"}
                          children={name}
                        />
                        <CheckCircleRoundedIcon
                          color="success"
                          style={{
                            height: "1rem",
                            marginLeft: "10px",
                            visibility:
                              seatChange && idnumber === passengers
                                ? ""
                                : "hidden",
                          }}
                        />
                      </div>
                      <Typography
                        id={`passengerClass-${i}`}
                        className={"passengerCardSecondaryInfo"}
                        children={`${getWagonClass(
                          stamformdetcode.split("-")[0],
                          stamformdetcode.split("-")[1]
                        )}`}
                      />
                      <Typography
                        id={`passengerSeat-${i}`}
                        className={"passengerCardPrimaryInfo"}
                        children={`${wagondetrow}${wagondetcol}`}
                      />
                    </div>
                  </div>
                </div>
              )
            );
          }
        )}
      </div>
    </>
  );
};

export default PassengerDetailCards;
