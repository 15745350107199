import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { Header, Footer } from "../index";
import { Divider, Paper, Typography } from "@mui/material";
import { getWagonClass, priceFormatter } from "../../common/Utils";
import uiConfig from "../../configs/uiConfig.json"

const SelectStationPriceCard = () => {
  const { stationpricecardPage } = uiConfig;
  const header = "Cari Jadwal Kereta";
  const navigate = useNavigate();
  const [trainScheduleData, setTrainScheduleData] = useState([]);
  const [stationDetails, setStationDetails] = useState([]);

  const handleTiciketSelect = (i) => {
    let KAI = JSON.parse(localStorage.getItem("KAI"));

    KAI.ticketDetails = trainScheduleData[i];
    KAI.noka = trainScheduleData[i].noka;
    localStorage.setItem("KAI", JSON.stringify(KAI));
    navigate("/inputdata");
  };

  useEffect(async () => {
    let KAI = await JSON.parse(localStorage.getItem("KAI"));
    if (!(KAI?.stationDetails && KAI?.trainScheduleResponse)) navigate("/");
    else {
      // remove any extra keys if available.
      KAI = {
        stationDetails: KAI.stationDetails,
        trainScheduleResponse: KAI.trainScheduleResponse,
      };
      localStorage.setItem("KAI", JSON.stringify(KAI));
      setTrainScheduleData(KAI.trainScheduleResponse.data);
      setStationDetails({
        date: KAI.stationDetails.date,
        origin: KAI.stationDetails.sourceStation,
        destination: KAI.stationDetails.destnationStation,
      });
    }
  }, []);

  const getTripDuration = (departdatetime, arrivaldatetime) => {
    const start = moment(departdatetime, "YYYY-MM-DD HH:mm");
    const end = moment(arrivaldatetime, "YYYY-MM-DD HH:mm");
    const duration = moment.duration(end.diff(start));
    const hours = duration.hours();
    const minutes = duration.minutes();
    return `${hours}j ${minutes}m`;
  };

  const isTiciketAvailable = (departdatetime, availability) => {
    const KAI = JSON.parse(localStorage.getItem("KAI"));
    const adultPassengers = KAI.stationDetails.adultPassengers;

    if (availability == 0 || availability < adultPassengers) {
      return false;
    }

    const { isCardValidTimeInHours } = stationpricecardPage;
    return (
      moment(departdatetime, "YYYY-MM-DD HH:mm").diff(moment.tz("Asia/Jakarta").format("YYYY-MM-DD HH:mm"), "hours") >= isCardValidTimeInHours // NOW SET TO 1 hour previously was 3 hours
    );
  };

  const seatAvalibilityStatus = (availability, isTicketValid) => {
    if (availability == 0) {
      return (
        <span id="seatAvailability" style={{ color: "#ED1C24" }}>
          Kursi Habis
        </span>
      );
    }
    if (availability > 50) {
      return <span id="seatAvailability">Tersedia</span>;
    } else {
      return (
        <span id="seatAvailability">
          {availability} Kursi
          <span
            style={{
              fontSize: "12px",
              fontWeight: "400",
              color: isTicketValid ? "#615a5a" : "#CCC",
            }}
          >
            {" "}
            tersedia
          </span>
        </span>
      );
    }
  };

  return (
    <div style={{ height: trainScheduleData.length > 3 ? "" : "100vh" }}>
      <Header header={header} back={"/"} step={"2"} />
      <div style={{ padding: "16px 16px 0 16px" }}>
        <Typography
          id="stationNames"
          className={"stationNames"}
          children={`${stationDetails.origin?.stationname} - ${stationDetails.destination?.stationname}`}
          align={"center"}
        ></Typography>
        <Typography
          id="selectedDuration"
          className={"selectedDuration"}
          children={moment(stationDetails.date)
            .local("id")
            .format("dddd, DD MMM YYYY")}
          align={"center"}
        ></Typography>
      </div>
      <div
        style={{
          padding: "16px",
          borderBottom: "1px solid #DDD",
          height: trainScheduleData.length > 3 ? "" : `calc(100vh - 151px)`,
        }}
      >
        {" "}
        {/* 151px => height(Header) + height(Footer) + 16px(Top & Bottom Padding of this div) */}
        {trainScheduleData.map(
          (
            {
              trainname,
              departuretime,
              arrivaltime,
              departdatetime,
              arrivaldatetime,
              stasiunorgcode,
              stasiundestcode,
              wagonclasscode,
              subclass,
              fares,
              departdate,
              arrivaldate,
              noka,
              availability,
            },
            i
          ) => {
            const tripDuration = getTripDuration(
              departdatetime,
              arrivaldatetime
            );
            const wagonClass = getWagonClass(wagonclasscode, subclass);
            const price = priceFormatter(fares[0].amount);
            const isTicketValid = isTiciketAvailable(
              departdatetime,
              availability
            );
            return (
              <Paper
                id={'ticketInfo-'+i}
                key={i}
                onClick={() => {
                  if (isTicketValid) handleTiciketSelect(i);
                }}
                style={{
                  padding: "16px",
                  borderRadius: "8px",
                  background: isTicketValid ? "" : "#F2F2F2",
                  boxShadow: isTicketValid
                    ? "0px 2px 10px rgba(0, 0, 0, 0.2)"
                    : "rgb(0 0 0 / 20%) 0px 0px 0px",
                  marginBottom: "16px",
                  cursor: isTicketValid ? "pointer" : "not-allowed",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Typography
                      id="trainName"
                      children={`${trainname} (${noka})`}
                      style={{ color: isTicketValid ? "#1A1A1A" : "#CCC" }}
                      className={"stationCardName"}
                    />
                    <Typography
                      id="trainClass"
                      children={wagonClass}
                      style={{ color: isTicketValid ? "#777" : "#CCC" }}
                      className={"stationCardClass"}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <Typography
                      id="trainPriceCard"
                      style={{
                        color: isTicketValid ? "#007DFE" : "#CCC",
                        whiteSpace: "pre",
                      }}
                      className={"stationCardPrice"}
                    >
                      {`Rp ${price}`}
                    </Typography>
                    <span
                      style={{
                        fontSize: "12px",
                        color: isTicketValid ? "#615A5A" : "#CCC",
                        fontWeight: "400",
                        marginTop: "5px",
                      }}
                    >
                      /orang
                    </span>
                    {/* <Typography children={"Habis"} style={{display: isTicketValid ? "" : "none", fontWeight:"bold", fontSize:"10px", color:"#999", textAlign:"right"}} /> */}
                  </div>
                </div>

                <div style={{ marginTop: "16px" }} className={"displayFlex"}>
                  <div style={{ display: "flex", marginTop: "16px" }}>
                    <div>
                      <Typography
                        id="sourceStationCode"
                        children={stasiunorgcode}
                        style={{ color: isTicketValid ? "#615A5A" : "#CCC" }}
                        className={"stationTimeLabel"}
                      />
                      <Typography
                        id="sourceStationTime"
                        children={
                          departuretime.substring(0, 2) +
                          ":" +
                          departuretime.substring(2, 4)
                        }
                        style={{
                          color: isTicketValid ? "#1A1A1A" : "#CCC",
                          fontWeight: "700",
                        }}
                        className={"stationTime"}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Divider style={{ width: "5vw", margin: "0 10px" }} />
                      <Typography
                        id="journeyTime"
                        children={tripDuration}
                        style={{
                          color: isTicketValid ? "#615A5A" : "#CCC",
                          fontWeight: "700",
                        }}
                        className={"totalDuration"}
                      />
                      <Divider style={{ width: "5vw", margin: "0 10px" }} />
                    </div>
                    <div>
                      <Typography
                        id="destinationStationCode"
                        children={stasiundestcode}
                        style={{ color: isTicketValid ? "#777" : "#CCC" }}
                        className={"stationTimeLabel"}
                      />
                      <Typography
                        id="destinationStationTime"
                        children={
                          arrivaltime.substring(0, 2) +
                          ":" +
                          arrivaltime.substring(2, 4)
                        }
                        style={{
                          color: isTicketValid ? "#1A1A1A" : "#CCC",
                          fontWeight: "700",
                        }}
                        className={"stationTime"}
                      />
                      <Typography
                        id="exceedsOneDay"
                        children={"+1 hari"}
                        style={{
                          color: isTicketValid ? "#1A1A1A" : "#CCC",
                          display: departdate != arrivaldate ? "" : "none",
                        }}
                        className={"stationTimeLabel"}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      color: isTicketValid ? "#1A1A1A" : "#CCC",
                      textAlign: "right",
                    }}
                    className={"stationCardPrice"}
                  >
                    {seatAvalibilityStatus(availability, isTicketValid)}
                  </div>
                </div>
              </Paper>
            );
          }
        )}
      </div>
      <Footer />
    </div>
  );
};

export default SelectStationPriceCard;
