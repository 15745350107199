import React, { useEffect, useState } from "react";
import { Button, IconButton, Paper, Slide, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PeopleIcon from "@mui/icons-material/People";
import ChildFriendlyIcon from "@mui/icons-material/ChildFriendly";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

function PassengerDialog({
  openPassengerDialog,
  setOpenPassengerDialog,
  setPassengerInfo,
}) {
  const [adultPassenger, setAdultPassenger] = useState(1);
  const [childPassenger, setChildPassenger] = useState(0);

  const subPassengers = (type, noOfPassenger) => {
    if (type === "adult" && noOfPassenger > 1) {
      setAdultPassenger(noOfPassenger - 1);
    }
    if (type === "child" && noOfPassenger > 0) {
      setChildPassenger(noOfPassenger - 1);
    }
  };

  const addPassengers = (type, noOfPassenger) => {
    if (type === "adult" && noOfPassenger < 4) {
      setAdultPassenger(noOfPassenger + 1);
    }
    if (type === "child" && noOfPassenger < adultPassenger) {
      setChildPassenger(noOfPassenger + 1);
    }
  };

  useEffect(() => {
    if (adultPassenger < childPassenger) {
      setChildPassenger(0);
    }
  }, [adultPassenger, childPassenger]);

  return (
    <div
      className={"passengerContainer"}
      style={{ display: openPassengerDialog ? "" : "none" }}
    >
      <Slide
        direction={"up"}
        in={openPassengerDialog}
        id="slider-container-passenger"
      >
        <div className={"passengerBlock"}>
          <Paper style={{ padding: "0 24px 40px 24px", background: "#FFF" }}>
            <div style={{ marginTop: "16px" }} className={"displayFlex"}>
              <Typography children={"Penumpang"} className={"popupTitle"} />
              <IconButton
                id={'closePassengerDrawer'}
                onClick={() => setOpenPassengerDialog(!openPassengerDialog)}
              >
                <CloseIcon style={{ color: "#D5D1D9" }} />
              </IconButton>
            </div>

            <div
              className={`${"passengerCartContainer"} ${"displayFlex"}`}
              style={{ marginTop: "24px" }}
            >
              <div className={"displayInlineFlex"}>
                <PeopleIcon className={"passengerCartIcon"} />
                <div style={{ marginLeft: "20px" }}>
                  <Typography
                    children={"Dewasa"}
                    className={"passengerCartTitle"}
                  />
                  <Typography
                    children={"≥ 3 Tahun"}
                    className={"passengerCartAgeInfo"}
                  />
                </div>
              </div>

              <div className={"displayInlineFlex"}>
                <IconButton
                  id={'removeAdultPassenger'}
                  onClick={() => subPassengers("adult", adultPassenger)}
                >
                  <RemoveCircleOutlineIcon
                    style={{
                      color: adultPassenger === 1 ? "#D5D1D9" : "#2C2D6D",
                    }}
                  />
                </IconButton>
                <Typography
                  id={'adultPassengerCount'}
                  className={"passengerAddedToCart"}
                  children={adultPassenger}
                />
                <IconButton
                  id={'addAdultPassenger'}
                  onClick={() => addPassengers("adult", adultPassenger)}
                >
                  <AddCircleOutlineIcon
                    style={{
                      color: adultPassenger < 4 ? "#2C2D6D" : "#D5D1D9",
                    }}
                  />
                </IconButton>
              </div>
            </div>

            <div
              className={`${"passengerCartContainer"} ${"displayFlex"}`}
              style={{ marginTop: "8px" }}
            >
              <div className={"displayInlineFlex"}>
                <ChildFriendlyIcon className={"passengerCartIcon"} />
                <div style={{ marginLeft: "20px" }}>
                  <Typography
                    children={"Bayi"}
                    className={"passengerCartTitle"}
                  />
                  <Typography
                    children={"< 3 Tahun"}
                    className={"passengerCartAgeInfo"}
                  />
                </div>
              </div>

              <div className={"displayInlineFlex"}>
                <IconButton
                  id={'removeChildPassenger'}
                  onClick={() => subPassengers("child", childPassenger)}
                >
                  <RemoveCircleOutlineIcon
                    style={{
                      color: childPassenger === 0 ? "#D5D1D9" : "#2C2D6D",
                    }}
                  />
                </IconButton>
                <Typography
                  id={'childPassengerCount'}
                  className={"passengerAddedToCart"}
                  children={childPassenger}
                />
                <IconButton
                  id={'addChildPassenger'}
                  onClick={() => addPassengers("child", childPassenger)}
                >
                  <AddCircleOutlineIcon
                    style={{
                      color:
                        childPassenger < adultPassenger ? "#2C2D6D" : "#D5D1D9",
                    }}
                  />
                </IconButton>
              </div>
            </div>

            <Button
              id={'submitPassengerCount'}
              fullWidth
              children={"Terapkan"}
              className={"submitOrangeButton"}
              onClick={() => {
                setPassengerInfo({
                  adult: adultPassenger,
                  child: childPassenger,
                });
                setOpenPassengerDialog(!openPassengerDialog);
              }}
            />
          </Paper>
        </div>
      </Slide>
    </div>
  );
}

export default PassengerDialog;
