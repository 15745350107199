import React, { useContext } from "react";
import { TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import moment from "moment";
import { id } from "date-fns/locale"; // this is localization for Indonesian Language......
import { PassengerContext } from "./Container";

const ImageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

const DateContainer = () => {
  const classes = useStyle();
  const Context = useContext(PassengerContext);
  const { departureDate, setDepartureDate } = Context;
  const maxSelectableDate = moment(new Date()).add(90, "days").toDate();

  return (
    <div id={'selectDateContainer'} style={{ marginBottom: "32px" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={ImageBaseUrl + "/iconCalendar.png"}
          width="24"
          alt="calendar"
          className={"iconColor"}
        />

        <div id={'selectedDateInfo'} style={{ marginLeft: "18px", flex: "1" }}>
          <Typography
            className={"formTitle"}
            children={"Tanggal keberangkatan"}
          />

          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={id}>
            <MobileDatePicker
              InputProps={{ style: { border: "0" } }}
              disablePast
              DialogProps={{ className: classes.selectDepartureDate }}
              value={departureDate}
              cancelText={""}
              maxDate={maxSelectableDate}
              onChange={(date) => setDepartureDate(date)}
              renderInput={(params) => {
                params.inputProps.value = moment(
                  params.inputProps.value,
                  "DD/MM/YYYY"
                ).format("dddd, DD MMM YYYY");
                return (
                  <TextField
                    fullWidth
                    className={classes.departureDateTextField}
                    {...params}
                    id="departureDate"
                  />
                );
              }}
            />
          </LocalizationProvider>
        </div>
      </div>
    </div>
  );
};

export default DateContainer;

const borderStyle = "0px solid #FFF";
const useStyle = makeStyles({
  selectDepartureDate: {
    // Hide Cancel Button
    "& .MuiDialogActions-root>:first-of-type": {
      display: "none",
    },
    // giv a line after the date displayed at top in Date Picker
    "& .PrivatePickersToolbar-root": {
      borderBottom: "1px solid #DDD !important",
    },
    // removed the edit button which changed the view of the date picker
    "& .MuiPickersToolbar-penIconButton": {
      display: "none",
    },
    // change color and background of the CANCEL and OK button
    "& .MuiButton-root": {
      background: "#DD6B00",
      color: "#FFF",
      "&:hover": {
        background: "#C96100",
      },
    },
    // background color applied to date when selected and also when seledted and hover on it
    "& .Mui-selected": {
      backgroundColor: "#007DFE !important",
      "&:hover": {
        backgroundColor: "#0671DF !important",
      },
    },
  },

  departureDateTextField: {
    // style APPLIED to TEXTFIELD
    "& .MuiOutlinedInput-input": {
      padding: "0 !important",
      fontWeight: "700 !important",
      fontSize: "16px !important",
      color: "#2C2D6D !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: borderStyle,
      outline: borderStyle,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: borderStyle,
      },
      "&.Mui-focused fieldset": {
        border: borderStyle,
      },
    },
  },
});
