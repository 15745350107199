import React, { useContext } from "react";
import { PassengerContext } from "./Container";
import { IconButton, Typography } from "@mui/material";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";

const StationContainer = () => {
  const Context = useContext(PassengerContext);
  const {
    startStation,
    stopStation,
    setStartStation,
    setStopStation,
    setOpenStartStationDropDown,
    setOpenStopStationDropDown,
  } = Context;

  const toggleStationNames = (startStationName, stopStationName) => {
    if (startStationName && stopStationName) {
      setStartStation(stopStationName);
      setStopStation(startStationName);
    }
  };

  return (
    <div
      id="Station-Container"
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "32px",
      }}
    >
      <div
        id={'startStationList'}
        style={{ flex: "1" }}
        onClick={() => setOpenStartStationDropDown(true)}
      >
        <Typography children="Dari" className={"formTitle"} align={"left"} />
        <Typography
          children={startStation.stationname || "Stasiun Asal"}
          className={"formData"}
          align={"left"}
          style={{ height: startStation ? "" : "24px" }}
        />
      </div>

      <div>
        <IconButton
          id={'toggleStationNamesButton'}
          className={"toggleButton"}
          onClick={() => toggleStationNames(startStation, stopStation)}
        >
          <CompareArrowsIcon />
        </IconButton>
      </div>

      <div
        id={'stopStationList'}
        style={{ flex: "1" }}
        onClick={() => setOpenStopStationDropDown(true)}
      >
        <Typography children="Ke" className={"formTitle"} align={"right"} />
        <Typography
          children={stopStation.stationname || "Stasiun Tujuan"}
          className={"formData"}
          align={"right"}
          style={{ height: stopStation ? "" : "24px" }}
        />
      </div>
    </div>
  );
};

export default StationContainer;
