import React, { useState } from "react";
import { Typography } from "@mui/material";
import { PopUpModal } from "../CommonComponents/PopUp/PopUpModal";

const Header = ({ header }) => {
  const [errorPopUp, showErrorPopUp] = useState({ showPopUp: false });

  const handlePopUpClose = () => {
    showErrorPopUp({ ...errorPopUp, showPopUp: false });
  };

  return (
    <div id="header" className="header">
      <PopUpModal
        open={errorPopUp.showPopUp}
        error={true}
        message={errorPopUp.message}
        handlePopUpClose={handlePopUpClose}
      />
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography id="headerText" className="headerText">
          {header}
        </Typography>
      </div>
    </div>
  );
};

export default Header;
