import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Button, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import StationContainer from "./StationContainer";
import DateContainer from "./DateContainer";
import PassengerContainer from "./PassengerContainer/PassengerContainer";
import { getSchedule } from "../../../api/ApiUtils";
import { PassengerContext } from "./Container";

const HomepageForm = ({ kaiImageHeight }) => {
  const navigate = useNavigate();
  const Context = useContext(PassengerContext);
  const {
    startStation,
    stopStation,
    passengerInfo: { adult, child },
    departureDate,
    setLoading,
    showErrorPopUp,
  } = Context;
  const [stationError, setStationError] = useState(false);

  const submitScheduleInfo = async () => {
    if (startStation && stopStation && startStation !== stopStation) {
      setStationError(false);
      const p = Promise.resolve(JSON.parse(localStorage.getItem("KAI")));

      p.then((res) => {
        let KAI = res == null ? {} : res;
        KAI.stationDetails = {
          sourceStation: startStation,
          destnationStation: stopStation,
          date: departureDate,
          adultPassengers: adult,
          childPassengers: child,
        };
        localStorage.setItem("KAI", JSON.stringify(KAI)); // store the station details in localStorage
      });
      const req = {
        originStationCode: startStation.stationcode,
        destinationStationCode: stopStation.stationcode,
        departureDate: moment(departureDate).format("YYYY-MM-DD"),
        numberOfAdult: adult,
        numberOfChildren: child,
      };
      setLoading(true);
      const scheduleResponse = await getSchedule(req);
      if (scheduleResponse.success) {
        p.then(() => navigate("/stationpricecard"));
        setLoading(false);
      } else {
        showErrorPopUp({ showPopUp: true, message: scheduleResponse.message });
        setLoading(false);
      }
    } else {
      setLoading(false);
      setStationError(true);
    }
  };

  useEffect(() => {
    if (startStation && stopStation && startStation !== stopStation) {
      setStationError(false);
    }
  }, [startStation, stopStation]);

  const generateStationErrorMessage = (startStation, stopStation) => {
    if (!startStation && !stopStation) {
      return "* Stasiun asal dan stasiun tujuan tidak boleh kosong";
    }
    if (!startStation) {
      return "* Stasiun asal tidak boleh kosong";
    }
    if (!stopStation) {
      return "* Stasiun tujuan tidak boleh kosong";
    }
    if (startStation === stopStation) {
      return "* Stasiun asal dan stasiun tujuan tidak boleh sama";
    }
    return "";
  };
  return (
    <div
      id="formContainer"
      className={"formContainer"} /*style={{bottom: '35%'}}*/
    >
      <StationContainer />
      <DateContainer />
      <PassengerContainer />
      {/* ERROR MESSAGE */}
      {stationError && (
        <Alert
          sx={{
            backgroundColor: "#FAE9EF",
            color: "#ff0000",
            fontSize: "12px",
            marginBottom: "12px",
            justifyContent: "center",
          }}
          icon={false}
          variant="outlined"
          severity="error"
        >
          {generateStationErrorMessage(startStation, stopStation)}
        </Alert>
      )}
      <Typography id={'termsandconditions'} className={"termsandconditions"}>
        Produk atau jasa ini disediakan dan menjadi tanggung jawab Kereta Api
        Indonesia (KAI). Untuk layanan Contact Center KAI, hubungi (021) 121
        atau email ke cs@kai.id. Cek Syarat & Ketentuan lengkap
        <a
          id={'termsandconditionsDocumentLink'}
          href="https://bankmandiri.co.id/livin/kai/syarat-dan-ketentuan?utm_source=urban-lifestyle&utm_medium=livinapp&utm_campaign=tnc-kai"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#007DFE", textDecoration: "none" }}
        >
          &nbsp; di sini.
        </a>
      </Typography>
      {/* SUBMIT BUTTON */}
      <Button
        id={'submitTrainInfo'}
        className={"submitOrangeButton"}
        children={"Cari Tiket Kereta Api"}
        fullWidth
        onClick={() => submitScheduleInfo()}
        align={"center"}
      />
    </div>
  );
};

export default HomepageForm;
